import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Stack } from '@mui/material';
import { Button } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// components
import { Layout } from 'src/components/layout-wrapper/Layout';
import { TextInput } from 'src/components/text-input/TextInput';
import { BackButton } from 'src/components/back-button/BackButton';
import Checkbox from 'src/components/checkbox/checkbox';
import SignUpStepper from 'src/components/stepper';
import Alert from 'src/components/alert/Alert';

// types and constants
import { AuthenticationAction, logIn, logOut } from 'src/lib/auth/actions';
import AuthService from 'src/lib/auth/auth-service';
import paymentAPI from '../deprecated/payment/api';
import { IAuthCredentials } from 'src/lib/auth/types';
import UserPayload from 'src/models/payload/UserPayload';
import { IAppState } from 'src/store';
import { EMAIL_IN_USE, USER_STATUS } from '../constants';
import { TagData, TagResponse } from 'src/store/signup-workflow/types';

// styles & utilities
import { useViewport } from 'src/lib/utils';
import { useAppSelector } from 'src/store/hooks';
import {
  setChallengeData,
  setSignUpUser,
  setSignupStep,
  setIsFutureCommencement,
  setIsChallengeAccepted,
} from 'src/store/signup';
import styles from './create-account.module.scss';
import colors from 'src/styles/equalution/colors';
import { EVENTS, track } from 'src/util/track';

enum SignupStatus {
  EmailInUse = 'Email In Use',
  Success = 'Success',
  Fail = 'Fail',
}

interface SignupFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!$@#%^&*()_+|~=`{}:";'<>?,./-]).{8,}$/,
      'Password must be at least 8 characters with 1 number, 1 symbol, 1 upper case, and 1 lower case letter'
    ),
});

const CreateAccount = () => {
  // hooks and states
  const { isMobile } = useViewport();
  // url params
  const searchParams = new URLSearchParams(window.location.search);

  // typeform quiz params
  let emailParam = searchParams.get('email');
  let fName = searchParams.get('firstName');
  let lName = searchParams.get('lastName');
  if (emailParam && emailParam.indexOf(' ') !== -1) {
    //handle missing url encoding value for email
    emailParam = emailParam.replace(/\s+/g, '+');
  }
  const initialValues: SignupFormValues = {
    firstName: fName || '',
    lastName: lName || '',
    email: emailParam || '',
    password: '',
  };

  const dispatch: ThunkDispatch<AuthenticationAction, any, AnyAction> =
    useDispatch();

  // signup status (success/fail)
  const [status, setStatus] = useState<SignupStatus>();

  // acknowledge and terms
  const [acknowledged, setAcknowledged] = useState(false);
  const [terms, setTerms] = useState(false);

  // opting challenge
  const [acceptChallenge, setAcceptChallenge] = useState(false);
  // future commencement
  const [futureCommencement, setFutureCommencement] = useState(false);

  // challenge data
  const [challengeOption, setChallengeOption] = useState<TagData | null>(null);

  const captchaRef = createRef<ReCAPTCHA>();

  const user = useSelector((state: IAppState) => state.auth?.user);
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  const { selectedPlanId, isFitherProjectUser, allPlansSorted } =
    useAppSelector(state => state.signUp);

  const isProspectOrInactiveUser =
    user?.status === USER_STATUS.PROSPECT ||
    user?.status === USER_STATUS.INACTIVE;

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      await submitForm(values);
    },
  });

  const getErrors = (key: keyof SignupFormValues): string | undefined => {
    if (formik.touched[key] && formik.errors[key]) {
      return formik.errors[key];
    }
  };

  const buttonDisabled = useMemo((): boolean => {
    if (
      !acknowledged ||
      !terms ||
      Object.keys(formik.errors).length > 0 ||
      Object.values(formik.values).some(value => value === '')
    ) {
      return true;
    }
    return false;
  }, [acknowledged, terms, formik]);

  // actions
  const doLogin = useCallback(
    (credentials: IAuthCredentials) => dispatch(logIn(credentials as any)),
    [dispatch]
  );
  const doLogout = useCallback(() => dispatch(logOut()), [dispatch]);

  const submitForm = useCallback(
    async (formValues: SignupFormValues): Promise<void> => {
      const token = await captchaRef.current?.executeAsync();

      const { firstName, lastName, email, password } = formValues;

      try {
        if (token) {
          doLogout();

          const { errorString } = await AuthService.saveUserProfile(
            new UserPayload(firstName, lastName, email, password),
            token
          );

          if (errorString?.includes(EMAIL_IN_USE)) {
            setStatus(SignupStatus.EmailInUse);
          } else if (errorString) {
            console.error('Error while attempting to save user', errorString);
            setStatus(SignupStatus.Fail);
          } else {
            dispatch(setSignUpUser({ firstName, lastName, email }));
            dispatch(setChallengeData(challengeOption));
            dispatch(setIsFutureCommencement(futureCommencement));
            dispatch(setIsChallengeAccepted(acceptChallenge));
            setStatus(SignupStatus.Success);
            doLogin({ email, password });
            track(EVENTS.createAccount, {
              firstName,
              lastName,
              email,
            });
            if (isFitherProjectUser) {
              track(EVENTS.fitHerUserAccountCreated, {
                firstName,
                lastName,
                email,
              });
            }
            if (acceptChallenge) {
              track(EVENTS.challengeOptIn, {
                firstName,
                lastName,
                email,
                challengeName: challengeOption?.challenge_name,
              });
            }
            if (futureCommencement) {
              track(EVENTS.futureCommencement, {
                firstName,
                lastName,
                email,
              });
            }
            dispatch(setSignupStep(3));
          }
        }
      } catch (error) {
        console.error('Error while attempting to save user', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      acceptChallenge,
      captchaRef,
      challengeOption,
      dispatch,
      doLogin,
      doLogout,
      futureCommencement,
      isAuthenticated,
      isFitherProjectUser,
      user,
    ]
  );

  useEffect(() => {
    (async () => {
      const { data } = await paymentAPI.post<TagResponse>('/challengeOptIn');
      if (!data?.data?.length) {
        return null;
      }
      setChallengeOption(data.data[0]);
    })();
  }, []);

  useEffect(() => {
    if (isFitherProjectUser && isProspectOrInactiveUser) {
      dispatch(setSignupStep(3));
    }
  }, [allPlansSorted, dispatch, isFitherProjectUser, isProspectOrInactiveUser]);

  // challenge details, optin and future commenncements
  const ChallengeComponent = useMemo(() => {
    const showChallenge = moment().isBetween(
      challengeOption?.opt_in_start_date,
      challengeOption?.opt_in_end_date
    );
    const challengeName = challengeOption?.challenge_name.trim().toUpperCase();
    const startDate = moment(challengeOption?.challenge_start_date).format(
      'DD MMMM'
    );

    if (!challengeOption || !showChallenge) {
      return null;
    }

    return (
      <>
        <Box
          color={colors.OFF_BLACK}
          fontSize={'14px'}
          fontWeight={600}
          mt={'10px'}
          mb={'5px'}>
          JOIN THE{' '}
          <a
            style={{
              color: colors.SALAD,
              textDecoration: 'none',
            }}
            target="_blank"
            rel="noreferrer"
            {...(challengeOption?.challenge_url
              ? { href: challengeOption?.challenge_url }
              : null)}>
            {challengeName} CHALLENGE
          </a>{' '}
          starting {startDate}
        </Box>

        <Checkbox
          greenColored
          checked={acceptChallenge}
          setChecked={() => {
            setAcceptChallenge(!acceptChallenge);
            if (acceptChallenge) {
              setFutureCommencement(false);
            }
          }}>
          Opt in to challenge
        </Checkbox>
        {!!acceptChallenge &&
          !!selectedPlanId.includes('Premium') &&
          !moment().isAfter(challengeOption?.opt_in_start_date) && (
            <Box>
              <Box
                color={colors.OFF_BLACK}
                fontSize={'14px'}
                fontWeight={400}
                mt={'10px'}
                mb={'5px'}>
                Want to only start your subscription at the challenge?
              </Box>
              <Box>
                <Checkbox
                  greenColored
                  checked={futureCommencement}
                  setChecked={setFutureCommencement}>
                  Pay now, start on the challenge
                </Checkbox>
              </Box>
            </Box>
          )}
        <Box sx={{ borderTop: '2px solid #d2cecb', marginTop: '10px' }} />
      </>
    );
  }, [acceptChallenge, challengeOption, futureCommencement, selectedPlanId]);

  return (
    <Layout module={isMobile ? undefined : 'login'}>
      <Stack sx={{ paddingBlock: isMobile ? '36px' : '58px' }} gap={'20px'}>
        <Stack className={styles.stackContainer} alignItems="center">
          <Box
            className={`${styles.backButtonWrapper} ${
              isMobile && styles.backButtonWrapper_mobile
            }`}>
            {!isFitherProjectUser && (
              <BackButton
                onPress={() => {
                  dispatch(setSignupStep(1));
                }}
              />
            )}
          </Box>
          <Box width={isFitherProjectUser ? 70 : 100}>
            <SignUpStepper
              activeStep={isFitherProjectUser ? 1 : 2}
              steps={isFitherProjectUser ? ['1', '2'] : ['1', '2', '3']}
            />
          </Box>
        </Stack>

        <Stack gap={isMobile ? '16px' : '64px'} alignItems="center">
          <Box className={styles.heading}>Create your account</Box>
          <Stack gap={isMobile ? '32px' : '16px'}>
            <Stack sx={{ width: isMobile ? 300 : 380 }} gap={'20px'}>
              <TextInput
                id="email"
                label="Email"
                placeholder="john.smith@gmail.com"
                disabled={!!emailParam}
                {...formik.getFieldProps('email')}
                errorText={getErrors('email')}
              />

              <TextInput
                label="Password"
                placeholder="•••••••••"
                passwordField
                {...formik.getFieldProps('password')}
                errorText={getErrors('password')}
              />

              <TextInput
                id="firstName"
                label="First Name"
                placeholder="John"
                {...formik.getFieldProps('firstName')}
                errorText={getErrors('firstName')}
              />

              <TextInput
                id="lastName"
                label="Last Name"
                placeholder="Smith"
                {...formik.getFieldProps('lastName')}
                errorText={getErrors('lastName')}
              />

              <Stack>
                {status === SignupStatus.EmailInUse && (
                  <Alert
                    message={
                      <Box>
                        This email is already in use. Please use another one or{' '}
                        <a className={styles.link} href="/login-account">
                          &nbsp;log in here.
                        </a>
                      </Box>
                    }
                  />
                )}
                {status === SignupStatus.Fail && (
                  <Alert
                    message={
                      'An unexpected error has occurred. Please try again later'
                    }
                  />
                )}
              </Stack>

              <Stack gap={'8px'}>
                {ChallengeComponent}
                <Checkbox
                  checked={acknowledged}
                  setChecked={setAcknowledged}
                  greenColored>
                  I acknowledge that I am not pregnant or have any underlying
                  health conditions.
                </Checkbox>
                <Checkbox checked={terms} setChecked={setTerms} greenColored>
                  I agree to the{' '}
                  <a
                    href="https://joinequ.com/terms-conditions/"
                    target="_blank"
                    className={styles.conditionText}
                    rel="noreferrer">
                    Terms and conditions
                  </a>
                </Checkbox>
              </Stack>
            </Stack>

            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="invisible"
              onExpired={(): void => setStatus(SignupStatus.Fail)}
              onErrored={(): void => setStatus(SignupStatus.Fail)}
            />

            <Box sx={{ paddingInline: isMobile ? 'unset' : '17px' }}>
              <Button
                type="submit"
                block
                disabled={buttonDisabled || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
                className={styles.button}>
                Create account
              </Button>
            </Box>
          </Stack>
        </Stack>
        <a href="/login-account" className={styles.helperText}>
          Already have an account? <span>Log in</span>
        </a>
      </Stack>
    </Layout>
  );
};

export default CreateAccount;
