import React, { createRef, useCallback, useState } from 'react';
import { Alert, Button, Form } from 'reactstrap';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import Popup from '../../../../components/content/popup/popup';
import ContentSection from '../../../../components/content/content-section';
import ContentWrapper from '../../../../components/content/content-wrapper';
import styles from './account-popup.module.scss';
import {
  AuthenticationAction,
  logIn,
  logOut,
} from '../../../../lib/auth/actions';
import { IAuthCredentials } from '../../../../lib/auth/types';
import EmailInput from '../../../../components/fields/email';
import PasswordInput from '../../../../components/fields/password';
import AuthService from '../../../../lib/auth/auth-service';
import UserPayload from '../../../../models/payload/UserPayload';
import TextInput from '../../../../components/fields/text';
import { IAppState } from '../../../../store';
import Checkbox from '../../../../components/checkbox/checkbox';
import { EMAIL_IN_USE } from 'src/pages/constants';
import { EVENTS, track } from 'src/util/track';

interface OwnProps {
  setPopupVisible: (val: boolean) => void;
}

type Props = OwnProps;

const schema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid').required('Required'),
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!$@#%^&*()_+|~=`{}:";'<>?,./-]).{8,}$/,
      {
        message:
          'Password must be at least 8 characters with 1 number, 1 symbol, 1 upper case and 1 lower case letter',
        excludeEmptyString: true,
      }
    ),
});

interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
// Handle email from Query params -> coming from quiz form for formik initial value
const searchParams = new URLSearchParams(window.location.search);
const urlParamEmail = searchParams.get('email') || '';

const initialValues: FormInputs = {
  firstName: '',
  lastName: '',
  email: urlParamEmail,
  password: '',
};

const AccountPopup = ({ setPopupVisible }: Props): JSX.Element => {
  // Redux Selectors and Actions
  const user = useSelector((state: IAppState) => state.auth?.user);

  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  const dispatch: ThunkDispatch<AuthenticationAction, any, AnyAction> =
    useDispatch();
  const doLogin = useCallback(
    (credentials: IAuthCredentials) => dispatch(logIn(credentials as any)),
    [dispatch]
  );
  const doLogout = useCallback(() => dispatch(logOut()), [dispatch]);

  const [status, setStatus] = useState('');
  const [acknowledged, setAcknowledged] = useState<boolean>(false);

  const captchaRef = createRef<ReCAPTCHA>();

  const submitForm = async (
    formValues: FormInputs,
    actions: any
  ): Promise<void> => {
    const token = await captchaRef.current?.executeAsync();

    const { firstName, lastName, email, password } = formValues;

    try {
      if (token) {
        if (!!user && isAuthenticated) {
          doLogout();
        }

        const { errorString } = await AuthService.saveUserProfile(
          new UserPayload(firstName, lastName, email, password),
          token
        );
        actions.setSubmitting(false);

        if (errorString && errorString.includes(EMAIL_IN_USE)) {
          setStatus('email already in use');
        } else if (errorString) {
          console.error('Error while attempting to save user', errorString);
          setStatus('fail');
        } else {
          setStatus('success');
          doLogin({ email, password });
          setPopupVisible(false);
          // set create-account event here
          track(EVENTS.createAccount, {
            firstName,
            lastName,
            email,
          });
        }
      }
    } catch (error) {
      actions.setSubmitting(false);
      console.error('Error while attempting to save user', error);
    }
  };

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection className={styles.accountPopup} collapseAt="sm">
          <Button
            className={styles.close}
            onClick={(): void => setPopupVisible(false)}
          />

          <h3>Sign up with email</h3>

          <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            initialValues={initialValues}>
            {(formikProps): JSX.Element => {
              return (
                <Form noValidate onSubmit={formikProps.handleSubmit}>
                  <Field
                    name="email"
                    label="Email"
                    setStatus={setStatus}
                    component={EmailInput}
                  />
                  <Field
                    name="password"
                    label="Password"
                    status={status}
                    component={PasswordInput}
                  />
                  <Field
                    name="firstName"
                    label="First Name"
                    component={TextInput}
                    placeholder="John"
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={TextInput}
                    placeholder="Smith"
                  />

                  {status === 'email already in use' ? (
                    <Alert color="danger">
                      This email is already in use. Please use another one or{' '}
                      <a className={styles.link} href="/login">
                        &nbsp;log in here.
                      </a>
                    </Alert>
                  ) : status === 'fail' ? (
                    <Alert color="danger">
                      An unexpected error has occurred. Please try again later
                    </Alert>
                  ) : null}

                  <Checkbox checked={acknowledged} setChecked={setAcknowledged}>
                    I acknowledge that I am not pregnant or have any underlying
                    health conditions.
                  </Checkbox>

                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size="invisible"
                    onExpired={(): void => setStatus('fail')}
                    onErrored={(): void => setStatus('fail')}
                  />
                  <Button
                    type="submit"
                    size="lg"
                    block
                    disabled={formikProps.isSubmitting || !acknowledged}
                    className={styles.button}>
                    Create account
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};

export default AccountPopup;
